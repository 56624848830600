import { Link } from 'react-router-dom'
import './SchoolItem.Modules.css'

const SchoolItem = ({ title, bannerUrl, id }) => {
  return (
      <div className='container_school'>
        <Link to={`/leitorescolas/${id}`} >
          <img src={bannerUrl} alt={title}/>
          <div className='school_name'> 
            <h3>{title}</h3>
          </div>
        </Link>
      </div>
  )
}

export default SchoolItem