const CarouselData = [
	{	
		id: 1,
		bannerUrl: 'https://i.ibb.co/mq881P6/LER-RELER.png',
		content: 'Departamento Municipal de Educação da inicio ao projeto "Ler & Reler" como os alunos do 1º ao 5º Ano '
	},
	{	
		id: 2,
		bannerUrl: 'https://i.ibb.co/17nqxqL/contando-cantando.png',
		content: 'Departamento Municipal de Educação da inicio ao projeto "Contando e Cantando" como os alunos de 0 a 5 anos '
	},
	{	
		id: 3,
		bannerUrl: 'https://i.ibb.co/RQSvQvr/348856971-240037755383225-658099237424478903-n.jpg',
		content: 'Realização do 3º encontro de profissionais da educação infantil pela primeira infância'
	},
	{	
		id: 4,
		bannerUrl: 'https://i.ibb.co/9rvZZ3r/projetoleitura-pais-amadeu-1.jpg',
		content: 'Projetos "Ler & Reler" e "Contando & Cantando" são apresentados aos pais em reunião'
	},
	{	
		id: 5,
		bannerUrl: 'https://i.ibb.co/sHcfHwB/Imagem-do-Whats-App-de-2023.jpg',
		content: 'Professora Maria Virgínia Zamparo Gonzales realiza trabalho de colagem com alunos'
	},
	{	
		id: 6,
		bannerUrl: 'https://i.ibb.co/TqBbTdK/351504583-552154730463802-4891951830459937465-n.jpg',
		content: 'Escolas Municipais “Amélia Ramos Stocco” e E. M. “Profª Armida L. P. Traldi” realizam trabalhos sobre “Dengue” e “Meio Ambiente”'
	},
	{	
		id: 7,
		bannerUrl: 'https://i.ibb.co/m4Lg7BK/352361917-994380445238708-7546887645838811818-n.jpg',
		content: 'Semana de combate ao abuso e á exploração sexual contra crianças e adolescentes na educação'
	},
	{	
		id: 8,
		bannerUrl: 'https://i.ibb.co/h1dLnm7/356668848-588650310087226-5639369176572663040-n.jpg',
		content: 'Apresentação da banda de concerto para os professores da rede municipal de ensino'
	},
	{	
		id: 9,
		bannerUrl: 'https://i.ibb.co/1r1dY0r/PHOTO-2023-06-23-13-42-57.jpg',
		content: 'Na E.M. “Prof Armida Leonora Pieri Traldi”, a festança foi animada!'
	},
	{	
		id: 10,
		bannerUrl: 'https://i.ibb.co/QdgpCNd/358058481-591578643127726-1226399176788476955-n.jpg',
		content: 'No último sábado, dia 1º de julho, encerrou-se o primeiro semestre do curso “Nós da Primeira Infância” com o tema “O brincar nos três primeiros anos”'
	},
	{	
		id: 11,
		bannerUrl: 'https://i.ibb.co/jbFvPMM/358073999-591601393125451-8262830929494375774-n.jpg',
		content: 'Setor de Meio Ambiente acompanhou os alunos das escolas municipais "Prefeito Amadeu Luis Margutti" e "Maria Aparecida Ungaretti"'
	},
	{	
		id: 12,
		bannerUrl: 'https://i.ibb.co/ChcnTPq/FOTO-1.jpg',
		content: 'Parceria entre Sesi-SP e Departamento Municipal de Educação de Santa Cruz das Palmeiras"'
	},
	{	
		id: 13,
		bannerUrl: 'https://i.ibb.co/KyFWdSf/363920902-610006797951577-2633730113326838235-n.jpg',
		content: 'Departamento Municipal de Educação e COMPEDE - Conselho Municipal do direito das pessoas com deficiências oferecem palestras'
	},
	{	
		id: 14,
		bannerUrl: 'https://i.ibb.co/r47NvQr/6de7096b-105c-41e3-a0f6-30059c8886ef.jpg',
		content: 'No dia 14 de Agosto de 2023 a escola EM “Amélia Ramos Stocco” completou 46 anos'
	},
	{	
		id: 15,
		bannerUrl: 'https://i.ibb.co/4dv5nbH/369455231-617350520550538-9001836139347005650-n.jpg',
		content: 'Atenção! Cadastro para o ano Letivo de 2024 para alunos que se encontram fora da escola.'
	},
	{	
		id: 16,
		bannerUrl: 'https://i.ibb.co/G03vwhf/372683171-619631960322394-4796798627925933717-n.jpg',
		content: 'Departamento Municipal de Educação em parceria com a Assistência Social confeccionou 1.200 sacolinhas de tecido laranja para os alunos da Educação Infantil.'
	},
	{	
		id: 16,
		bannerUrl: 'https://i.ibb.co/bNZDpcZ/375337067-622915726660684-3402965499168863094-n.jpg',
		content: 'Educação infantil comemora o 7 de setembro.'
	},
	{	
		id: 17,
		bannerUrl: 'https://i.ibb.co/sHz7Qk2/381165465-631894939096096-9163258643822623872-n.jpg',
		content: 'Alunos da escola Municipal "José Deperon Filho" realizam visita a Usina São Luis em comemoração ao dia da árvore'
	},
	{	
		id: 18,
		bannerUrl: 'https://i.ibb.co/SNkTM7v/383156146-634130785539178-397125246862388380-n.jpg',
		content: 'Educação infantil comemora o mês de setembro com várias atividades!'
	},
	{	
		id: 19,
		bannerUrl: 'https://i.ibb.co/jvmfzv8/387213141-641812164771040-8505249023903654591-n.jpg',
		content: 'Prefeitura Municipal e COMBEA realizam o 2° Concurso de Bem-estar Animal'
	},
	{	
		id: 20,
		bannerUrl: 'https://i.ibb.co/jwhTLYd/391619257-645720011046922-50216965228550287-n.jpg',
		content: 'Departamento de Educação Municiapl comemora semana das crianças'
	},
	{	
		id: 21,
		bannerUrl: 'https://i.ibb.co/BgtNr4q/391603120-645754944376762-7996899759519319165-n.jpg',
		content: 'Programa Concurso Cultural SICOOB – escolas municipais têm alunos premiados'
	},
	{	
		id: 22,
		bannerUrl: 'https://i.ibb.co/b5dD4mr/394688081-648412880777635-9086580981797711157-n.jpg',
		content: ''
	},
	{	
		id: 23,
		bannerUrl: 'https://i.ibb.co/Y2q8GRX/404449507-663152935970296-3419052870303199141-n.jpg',
		content: 'Educação infantil comemora mês de novembro'
	},
	{	
		id: 24,
		bannerUrl: 'https://i.ibb.co/SBxGJVz/Whats-App-Image-2023-12-04-at-13-47-38.jpg',
		content: 'Durante o mês de novembro profissionais do Departamento Municipal de Educação realizaram uma dinâmica com os alunos dos Pré II da Educação infantil'
	},
	{	
		id: 25,
		bannerUrl: 'https://i.ibb.co/f0nRrpw/Whats-App-Image-2023-12-04-at-08-17-50-2.jpg',
		content: 'No último dia 21 de novembro, profissionais da Educação Infantil receberam uma formação com o Professor Marcus Felipe Scozzafave com o tema “O Lúdico na Educação Infantil"'
	},
	{	
		id: 26,
		bannerUrl: 'https://i.ibb.co/Lk99mfz/Whats-App-Image-2023-12-04-at-10-59-23.jpg',
		content: 'No dia 28 de novembro, a comissão de elaboração do Plano Municipal pela Primeira Infância'
	},
	{	
		id: 27,
		bannerUrl: 'https://i.ibb.co/7XGYHJM/409852288-673682881583968-9005302698567887298-n.jpg',
		content: 'Encerramento do Projeto Ler & Reler'
	},
	{	
		id: 28,
		bannerUrl: 'https://i.ibb.co/vHn4VbX/409871460-673683674917222-6992734026224262128-n.jpg',
		content: 'Realizada feira dos Jovens Empreendedores nas Escolas Municipais'
	},
	{	
		id: 26,
		bannerUrl: 'https://i.ibb.co/7S4ySk3/409848881-673689381583318-751655936497037732-n.jpg',
		content: 'Escola Municipal “Prefº Amadeu Luís Margutti” Recebe prêmio da ABAG/SP'
	},
	{	
		id: 27,
		bannerUrl: 'https://i.ibb.co/FYyQ5YX/FOTO-DENGUE.jpg',
		content: 'Durante o mês de março, professores da Educação Infantil desenvolveram um belíssimo trabalho com os alunos utilizando estratégias e recursos eficazes enfatizando os temas.'
	},
	{	
		id: 28,
		bannerUrl: 'https://i.ibb.co/zNfY4JT/DIA-DOS-POVOS-IND-GENAS.jpg',
		content: 'As escolas de Educação Infantil desenvolveram atividades relacionadas às datas comemorativas do mês de abril.'
	},
	{	
		id: 29,
		bannerUrl: 'https://i.ibb.co/fnMCPF1/DIA-DA-FAM-LIA-NA-ESCOLA.jpg',
		content: 'E.M. “Amélia Ramos Stocco” realiza o "Dia da família na escola"'
	},
	{	
		id: 29,
		bannerUrl: 'https://i.ibb.co/wC2HKZN/Whats-App-Image-2024-05-26-at-22-30-58.jpg',
		content: 'Alunos da Educação Infantil participaram de um espetáculo teatral e aprendem a importância de poupar'
	}
]

export default CarouselData