
import { Swiper, SwiperSlide } from 'swiper/react'
import CarouselData from '../../data/CarouselData'
import './Carousel.Modules.css'
import 'swiper/css'
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import {  EffectFade ,Keyboard, Pagination, Navigation, Autoplay } from "swiper";

const Carousel = () => {

  return (
    <div  className='container_carousel'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        effect={"fade"}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={{delay: 5000}}
        modules={[ EffectFade ,Keyboard, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {CarouselData.reverse().map((data, id) => (
          (id <= 9 && (
            <SwiperSlide>
              <div className='carousel_image'>
                <center>
                <img src={data.bannerUrl} alt="" />
                  {data.content  ? (
                    <div className='carousel_text'>
                      <p>{data.content}</p>
                    </div>
                  ) : (
                    <p>s</p>
                  )

                  }
                  
                </center>
              </div>
            </SwiperSlide>
          ))
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel