import SchoolsAll from '../../components/schoolsAll/SchoolsAll'
import Notice from '../../components/notice/Notice'
import Title from '../../components/title/Title'
import NewsData from '../../data/NewsData'
import Carousel from '../../components/carousel/Carousel'
import { useState } from 'react'
import { MdExpandLess } from 'react-icons/md'
import { MdExpandMore } from 'react-icons/md'
import './Home.Modules.css'

const Home = () => {

  const [count, setCount] = useState(5)

  const NoticesReverse = () => {
    return [...NewsData].reverse()
  }

  const data = NoticesReverse()

  return (
    <div>
      <Carousel/>
      <div className='container_home'>
        <Title
          title='Notícias'
          subtitle='Confira as últimas noticias!'
        />
        {data.map((news, id) => (
          (id <= count && (
            <Notice
              key={id}
              id={news.id}
              bannerUrl={news.bannerUrl}
              title={news.title}
              prev={news.prev} 
              author={news.author}
              day={news.day}
              mouth={news.mouth}
              year={news.year}
            />
          )) 
        ))}
        <div className='home_seeMore'>
          {count <= data.length && (
            <button onClick={() => setCount(count >= NewsData.length? 5 : NewsData.length)}>{count === NewsData.length? <MdExpandLess/> : <MdExpandMore/>}</button>
          )}
        </div>
        <Title
          title='Escolas'
          subtitle='Conheça mais sobre a sua escola ou a escola do seu filho!' 
        />
        <SchoolsAll
          typeAll="content" 
        />
      </div>
    </div>
  )
}

export default Home 