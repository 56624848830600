
import './Footer.Modules.css'
const Footer = () => {
  return (
    <div className='container_all'>
      <div className='container_footer'>
        <div className='footer'>
          <div className='footer_members'>
            <h3>Prefeitura Municipal de Santa Cruz das Palmeiras</h3>
            <p>Endereço: Praça Condessa Monteiro de Barros, 507 - Centro - Santa Cruz das Palmeiras </p>
            <p>Telefone: (19) 3672-9292</p>
            <p>E-mail: www.santacruzdaspalmeiras.sp.gov.br/</p>
          </div>
          <div className='footer_members'>
            <h3>Departamento Municipal de Educação</h3>
            <p>Endereço: Rua Beatriz, 19 - Jardim São Carlos - Santa Cruz das Palmeiras</p>
            <p>Telefone: (19) 3672-6338</p>
            <p>E-mail: educamum@scpalmeiras.sp.gov.br</p>
          </div>
          <div className='footer_members'>
            <h3>InoTech Solutions</h3>
            <p>Endereço: Rua Nicolau Torelli, 248 - Jardim Alvorada - Tambaú</p>
            <p>Telefone: (19) 99452-3911</p>
            <p>E-mail: inotechsolutionsdigital@gmail.com</p>
          </div>
        </div>
      </div>
      <div className='footer_end'>
        <p>TODOS OS DIREITOS RESERVADOS | 2.0.0</p>
      </div>
    </div>
  )
}

export default Footer