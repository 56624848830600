import SchoolItem from '../schooltem/SchoolItem' 
import SchoolsData from '../../data/SchoolsData'
import './SchoolsAll.Modules.css'

const SchoolsAll = () => {
  return (
    <div>
      <section className='container_schoolHome'> 
        <div className="schoolHome">
          {SchoolsData.map((school, id,  typeAll ) => (
            // (school.id < 13 & (
            //   <p>{school.id}</p>
            //   <SchoolItem 
            //     key={id} 
            //     id={school.id}
            //     title={school.title} 
            //     bannerUrl={school.bannerUrl}
            //   />
            // ))
            (school.id < 13 && (
              <SchoolItem 
                key={id} 
                id={school.id}
                title={school.title} 
                bannerUrl={school.bannerUrl}
              />
            ))
          ))}
        </div>
      </section>
    </div>
  )
}

export default SchoolsAll