import SchoolsAll from "../../components/schoolsAll/SchoolsAll"
import Title from "../../components/title/Title"
import './Schools.Modules.css'

const Schools = () => {
  return (
    <div className='container_schools'>
      <Title
        title='Escolas'
        subtitle='Conheça mais sobre a sua escola ou a escola do seu filho!' 
      />  
      <SchoolsAll
        typeAll="content"
      />
    </div>
  )
}

export default Schools 