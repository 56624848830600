import Reader from '../../components/reader/Reader'
import Gallery from '../../components/gallery/Gallery'

const NoticesReader = () => {
  const path = window.location.href
  const last = path.lastIndexOf('/')
  const minus = Number(path.length - last - 1)  
  const id = Number(path.slice(-(minus)))

  return (
    <div className="container_noticesReader">
      <Reader
        id={id} 
        type='notice'
      />    
      <Gallery
        id={id}
        type='notice'
      />
    </div>
  )
}

export default NoticesReader