import Reader from '../../components/reader/Reader'
import Notice from '../../components/notice/Notice'
import NewsData from '../../data/NewsData'
import Gallery from '../../components/gallery/Gallery'
import Title from '../../components/title/Title'
import './SchoolReader.Modules.css'

const SchoolsReader = () => {
  const path = window.location.href
  const last = path.lastIndexOf('/')
  const minus = Number(path.length - last - 1)  
  const id = Number(path.slice(-(minus)))

  const newsSchool = NewsData.filter((newsData) => newsData.idSchool === id || newsData.idSchool === 13)
    .map((dataSchool) => dataSchool)
  
  const NoticesReverse = () => {
    return [...newsSchool].reverse()
  }

  const notices = NoticesReverse()
  return (
    <div>
      <Reader
        id={id} 
        type='school'
      />
      <div className='newsSchool'>
        <Title
          title='Notícias'
          subtitle='Confira as notícias referentes a essa escola'
        />
        {notices.length > 0 ?
          (notices.map((news, id) => (
            <Notice
              key={id}
              id={news.id}
              bannerUrl={news.bannerUrl}
              title={news.title}
              prev={news.prev} 
              author={news.author}
              day={news.day}
              mouth={news.mouth}
              year={news.year}
            />
          ))) :
          (
            <div className='newsSchool_dont'>
              <p>Não há notícais relacionadas a essa escola</p>
            </div>
            
          )
        }
      </div>
      <Gallery
        id={id}
        type='school'
      />
    </div>
  )
}

export default SchoolsReader