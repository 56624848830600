import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BsHouseDoorFill } from 'react-icons/bs'
import { IoIosSchool } from 'react-icons/io'
import { ImNewspaper } from 'react-icons/im'
import './NavBarMobile.Modules.css'

const NavBar = () => {
  const [active, setMode] = useState(false)
  const ToggleMode  = () => {
    setMode(!active)
  }
   return (
    <div className='container_navbar'>
      <div className='navbar'>
        <div className={active ? 'icon iconActive' : 'icon'} onClick={ToggleMode}>
          <div className="hamburguer hamburguerIcon"></div>
        </div>
      </div>
      <div className={active ? 'menu menuOpen' : 'menu menuClose'}>
        <div className='list'>
          <div className='listItem'>
            <li><Link to='/'><BsHouseDoorFill className="listIcon"/>Página Inicial</Link></li>
            <li><Link to='/escolas'><IoIosSchool className="listIcon"/>Escolas</Link></li>
            <li><Link to='/noticias'><ImNewspaper className="listIcon"/>Noticias</Link></li>
          </div>
        </div>
      </div>
      {/* <p><FaFacebook/></p> */}
    </div>
  )
}

export default NavBar