import NewsData from '../../data/NewsData'
import SchoolsData from '../../data/SchoolsData'
import parse from 'html-react-parser'
import './Reader.Modules.css'
import ReactPlayer from 'react-player'


const Reader = ( { id, type }) => {
  const verification = () => {
    if (type === 'notice') {
      return NewsData
    } else {
      return SchoolsData
    }
  }

  const data = verification()


  return (
    <div className='content_reader'>
      {data.filter((data) => data.id === id)
        .map((filtered, id) => (
          <div key={id}>
            { type === 'school' && (
                <div className='noticeBannner'>
                  <img src={filtered.bannerUrl} alt="" />
                </div>
              )
            }
            <h2>{(filtered.title).toUpperCase()}</h2>
            { type === 'notice' && (
                <div className='reader_date'>
                  <p>{`${filtered.author} ${filtered.day}/${filtered.mouth}/${filtered.year}`}</p>
                </div>
              )
            }
            {parse(filtered.content)}
            {filtered.video !== '' && (
              <div className='reader_video'>
                <ReactPlayer url={filtered.video} />
              </div>
            )}
            { type === 'school' && (
                <div className='reader_address'>
                  <h4>Endereço</h4>
                  <p>Rua: <span>{filtered.address}</span></p>
                  <p>Vila: <span>{filtered.neighborhood}</span></p>
                  <p>Telefone: <span>{filtered.phone}</span></p>
                  <p>Email: <span>{filtered.email}</span></p>
                </div>
              )
            }

          </div>
      ))}
    </div>
  )
}

export default Reader 