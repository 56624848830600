import Notice from '../../components/notice/Notice'
import Title from '../../components/title/Title'
import NewsData from "../../data/NewsData"
import './Notices.Modules.css'

const Notices = () => {
  const NoticesReverse = () => {
    return [...NewsData].reverse()
  }

  return (
    <div className='container_notices'>
      <Title 
        title='Noticias'
        subtitle='Confira as últimas noticias!'
      />
      {NoticesReverse().map((news, id) => (
        <Notice
          key={id}
          id={news.id}
          bannerUrl={news.bannerUrl}
          title={news.title}
          prev={news.prev} 
          author={news.author}
          day={news.day}
          mouth={news.mouth}
          year={news.year}
        />
      ))}
    </div>
  )
}

export default Notices