import './Notice.Modules.css'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

const Notice = ({ id, 
  title,
  bannerUrl,
  prev,
  author,
  day,
  mouth,
  year 
}) => {
  return (
    <div>
      <Link to={`/leitordenoticias/${id}`} >
        <div className='container_notice'>
          <div className='notice_img'>
            <img src={ bannerUrl } alt="imagem" />
          </div>
          <div className='notice_text'>
            <div className="text_author">
              <p>{`${author} ${day}/${mouth}/${year}`}</p>
            </div>
            <div className='text_title'>
              <h3>{ title.toUpperCase() }</h3>
            </div>
            <div className='text_prev'>
              {parse(prev)} 
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Notice