import NewsData from '../../data/NewsData'
import SchoolsData from '../../data/SchoolsData'
import Title from '../title/Title'
import Modal from 'react-modal'
import './Gallery.Modules.css'
import { useState } from 'react'

const Gallery = ( {id, type} ) => {
  Modal.setAppElement('#root');

  const verification = () => {
    if (type === 'notice') {
      return NewsData
    } else {
      return SchoolsData
    }
  }

  const data = verification()

  const gallery = data.filter(news => news.id === id).map((filteredNews => {
    return filteredNews.gallery
  }))

  const [modalIsOpen, setIsOpen] = useState(false)
  const [idModal, setModal] = useState(gallery.id)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container_images">
      <Title
          title='Fotos'
      />
      <div className='images'>
        {gallery[0].map((gallery, id) => (
          <div className='image_config' onClick={() => setModal(gallery.id)} key={id}>
            <img src={gallery.url} alt={gallery.alt} onClick={openModal} key={id}/> 
          </div>
        ))}
      </div>
      {gallery[0].filter((gallery) => gallery.id === idModal)
        .map((galleryModal, id) => (
          <Modal
          key={id}
          isOpen={modalIsOpen}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0,  0, 0.75)',
              filter: 'blur(0)',
              border: 'none'
            },
            content: {
              position: 'absolute',
              left: '40px',
              right: '40px',
              bottom: '40px',
              background: 'rgba(255, 255,  255, 0)',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
              border: 'none',
            }
          }}
          > 
            <div className='images_modal'>
              <div>
                <button onClick={closeModal} className='modal_button close'>X</button>
              </div>
              <img src={galleryModal.url} alt={galleryModal.alt} />
              {/* <p>{`Imagem ${galleryModal.id} de ${gallery[0].length}`}</p> */}
            </div>
          </Modal>
        ))}
    </div>
  )
}

export default Gallery