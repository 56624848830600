import { Link } from 'react-router-dom'
import './navbarDesktop.Modules.css'

const NavbarDesktop = () => {
  return (
    <div className="container_navDesktop">
      <div className='navDesktop_title'>
        <p className='title_primary'>EDUCAÇÃO EM FOCO</p>
        <p className='title_secondary'>Santa Cruz das Palmeiras - SP</p>
      </div>
      <div className='navDesktop_item'>
        <li className='item_main'><Link to='/'>Página Inicial</Link></li>
        <li><Link to='/escolas'>Escolas</Link></li>
        <li><Link to='/noticias'>Noticias</Link></li>
      </div>
    </div>
  )
}

export default NavbarDesktop