import NavBarMobile from './components/navBarMobile/NavBarMobile';
import NavbarDesktop from './components/navbarDesktop/navbarDesktop';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Schools from './pages/schools/Schools';
import Notices from './pages/notices/Notices';
import NewsReader from './pages/newsReader/NewsReader'
import SchoolsReader from './pages/schoolsReader/SchoolsReader';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  const  width = () => {
    return window.screen.width
  }

  const widthScreen = width()
  return (
    <div className='App'>
      <Router>
        {widthScreen <= 720 ? (
          // <NavBar/>
          <NavBarMobile/>
        ) : (
          <NavbarDesktop/>
        )}
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path='/escolas' element={<Schools/>}/>
          <Route path='/noticias' element={<Notices/>}/>
          <Route path='/leitordenoticias/:id' element={<NewsReader/>}/>
          <Route path='/leitorescolas/:id' element={<SchoolsReader/>}/>
        </Routes>
        <div className='footer'>
          <Footer/>
        </div>
      </Router>
    </div>
  );
}

export default App;
